:root {
  --skyblue: #00b5e6;
  --blue: #8853f4;
  --white: #fff;
  --ht: #be2431;
  --it: #214697;
  --rt: #e8b100;
  --green: #6fbc44;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  --primaryColor: #f6ac29;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root {
  height: calc(100vh - 60px);
}

.closeButton {
  top: 8px;
  color: #1d1d1b;
  right: 8px;
  position: absolute;
  background-color: #f6ac29;
  font-size: 1rem;
  padding: 3px;
}

.MuiSwitch-thumb {
  background-color: red;
}

/* pretty radio */

label > input[type="radio"] {
  display: none;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin-right: 1rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
  top: 0;
  position: relative;
}

label > input[type="radio"]:checked + * {
  /* color: #F6AC29; */
}

input[type="radio"]:checked + label {
  background: brown;
}

label > input[type="radio"]:checked + *::before {
  background: radial-gradient(
    #6fbc44 0%,
    #6fbc44 40%,
    transparent 50%,
    transparent
  );
  border-color: #000;
  background-color: #6fbc44;
}

/* basic layout */

fieldset {
  margin: 20px;
  max-width: 400px;
}

label > input[type="radio"] + * {
  /* display: inline-block; */
  padding: 0.5rem 0;
}

.css-10znog8 {
  background-color: #f6ac29 !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50px !important;
}

.MuiDialogContent-dividers {
  border-top: none !important;
  border-bottom: none !important;
  padding: 0 24px 16px !important;
  text-align: center;
  font: normal normal bold 24px/28px PoppinsBold;
  letter-spacing: 0px;
  color: #1d1d1b;
}

.MuiDialogContent-dividers p {
  font: normal normal 400 20px/28px PoppinsMedium;
}

#customized-dialog-content p {
  font: normal normal 400 16px/24px PoppinsMedium;
}

#customized-dialog-content {
  border-top: none !important;
  border-bottom: none !important;
  padding: 0 24px 16px !important;
  text-align: center;
  font: normal normal bold 24px/28px PoppinsBold;
  letter-spacing: 0px;
  color: #1d1d1b;
}

#customized-dialog-title button {
  background-color: #f6ac29;
  width: 1.2rem;
  height: 1.3rem;
  color: #1d1d1b;
}

#customized-dialog-title button svg {
  width: 0.8rem;
  height: 0.8rem;
}

.MuiDialogTitle-root {
  padding: 16px 24px 0 !important;
}

.MuiDialogTitle-root .Component-closeButton {
  background-color: #f6ac29;
  padding: 2px;
}

.MuiDialogTitle-root .Component-closeButton .MuiSvgIcon-root {
  width: 0.8rem;
  height: 0.8rem;
}

.selectedAnswer {
  /* background: #f0f1f5 0% 0% no-repeat padding-box; */
  border-radius: 33px;
  margin-left: -12px;
  padding: 0 12px;
  color: #6fbc44;
}

.selectedDarkAnswer {
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 33px;
  margin-left: -12px;
  padding: 0 12px;
  color: #6fbc44;
}

.selectedSkyAnswer {
  background: #fff7cb 0% 0% no-repeat padding-box;
  border-radius: 33px;
  margin-left: -12px;
  padding: 0 12px;
}

.selectedJungleAnswer {
  background: #fff7cb 0% 0% no-repeat padding-box;
  border-radius: 33px;
  margin-left: -12px;
  padding: 0 12px;
}

.selectedClassAnswer {
  background: #fff7cb 0% 0% no-repeat padding-box;
  border-radius: 33px;
  margin-left: -12px;
  padding: 0 12px;
}

.selectedSkyDarkAnswer {
  background: #2c250b 0% 0% no-repeat padding-box;
  border-radius: 33px;
  margin-left: -12px;
  padding: 0 12px;
}

.selectedJungleDarkAnswer {
  background: #2c250b 0% 0% no-repeat padding-box;
  border-radius: 33px;
  margin-left: -12px;
  padding: 0 12px;
}

.selectedClassDarkAnswer {
  background: #2c250b 0% 0% no-repeat padding-box;
  border-radius: 33px;
  margin-left: -12px;
  padding: 0 12px;
}

.selectedAnswer .customRadioButtonLabel {
  opacity: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* switch CSS */

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label.labelSwitch {
  cursor: pointer;
  text-indent: -9999px;
  width: 54px;
  height: 26px;
  background: #626262;
  display: inline-block;
  border-radius: 100px;
  position: relative;
  margin-top: -20px;
  margin-right: 10px;
  margin-left: -6px;
}

.feedback-btn {
  cursor: pointer;
  font: normal normal 500 29px/39px PoppinsMedium;
  margin: 48px 0;
  padding: 6px 48px;
  border: 2pt solid #151515;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 2pt solid #151515;
  opacity: 0.25;
  text-align: center;
  font: normal normal 400 18px/24px PoppinsMedium;
  letter-spacing: 0px;
  color: #151515;
  opacity: 0.25;
}
.feedback-selected {
  /* background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 2pt solid #151515;
  opacity: 0.25;
  text-align: center;
  font: normal normal 400 18px/24px PoppinsMedium;
  letter-spacing: 0px;
  color: #151515; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #151515;
  opacity: 1;
}

label.labelSwitch:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 5px;
  width: 18px;
  height: 18px;
  /* background: #F6AC29 url('./assets/images/sun.png') no-repeat center; */
  background: #ffcd05 url("./assets/images/swith_to_dark.svg") no-repeat center;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label.labelSwitch:after {
  background-color: gray;
}

input:checked + label.labelSwitch:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label.labelSwitch:active:after {
  width: 54px;
}

.questionBg {
  width: 100%;
  height: 89vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.footerBg {
  width: 100%;
  height: 26vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0px;
  background-position: center bottom;
  background-color: transparent;
}

.description {
  margin-top: 6px;
  opacity: 0.7;
  font-size: 18px;
  line-height: 30px;
  font-family: PoppinsMedium;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0;
  color: #1d1d1b;
}

.mb-16 {
  margin-bottom: 16px;
}

/* image fancybox */

.react-fancybox .thumbnail {
  padding: 32px;
}
.react-fancybox .thumbnail img {
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  z-index: 9999;
}

.react-fancybox .close-btn {
  cursor: pointer;
}

.react-fancybox .box {
  background-color: rgb(0 0 0 / 90%) !important;
  /* backdrop-filter: blur(25px); */
}

.react-fancybox .box .image-box {
  width: 600px;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  border: 1px solid #ddd;
}

.MuiAccordion-root {
  color: #000 !important;
  border-bottom: 1px solid #ddd;
  background-color: #fff !important;
}

.MuiAccordionSummary-root .MuiIconButton-label {
  color: #f6ac29;
}

.MuiAccordionSummary-content .MuiTypography-body1 {
  font-family: PoppinsSemiBold;
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

:root {
  scrollbar-color: #f6ac29 !important;
  scrollbar-width: thin !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #f6ac29;
}

/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */

.accordionContainer {
  /* scrollbar-color: rebeccapurple green; */
  scrollbar-face-color: #f6ac29 !important;
  scrollbar-shadow-color: #f6ac29 !important;
  scrollbar-highlight-color: #f6ac29 !important;
  scrollbar-3dlight-color: #f6ac29 !important;
  scrollbar-darkshadow-color: #f6ac29 !important;
  scrollbar-track-color: #f6ac29 !important;
  scrollbar-arrow-color: #f6ac29 !important;
}

.registerCodeForm div > input {
  background: #121210 0% 0% no-repeat padding-box;
  border: 1px solid #343639;
  border-radius: 6px;
  opacity: 1;
  width: 44px;
  height: 50px;
}

.otpContainer {
  margin: 1rem auto;
}

.otpInput {
  color: #fff;
  /* width: 2rem !important;
  height: 2rem; */
  margin: 0 0.5rem 0.5rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #121210 0% 0% no-repeat padding-box;
}

.otpContainer > .otpInput {
  border-radius: 0;
  margin: 0 0.3rem 0.5rem 0;
  background: #626262 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  opacity: 1;
  height: 45px;
  width: 45px;
}

.custom-accordian {
  margin-bottom: 6px !important;
}

.acc-title {
  font: normal normal bold 16px/30px PoppinsBold;
  letter-spacing: 0px;
  color: #1d1d1b;
}

.acc-desc {
  font: normal normal 600 14px/20px PoppinsSemiBold;
  letter-spacing: 0px;
  color: #1d1d1b;
}

@media only screen and (max-width: 550px) {
  .react-fancybox .box .image-box {
    width: 480px !important;
    max-width: 480px !important;
  }
}

@media only screen and (max-width: 600px) {
  .react-fancybox .box .image-box {
    width: 550px;
    max-width: 550px;
  }
}

/* Media Queries */

@media only screen and (max-width: 480px) {
  .description {
    font: normal normal bold 12px/14px PoppinsSemiBold;
  }
  .react-fancybox .box .image-box {
    width: 300px;
    max-width: 300px;
  }
  #customized-dialog-content {
    font: normal normal 400 18px/28px PoppinsMedium;
  }
  #customized-dialog-content p {
    font: normal normal 400 14px/24px PoppinsMedium;
  }
  .MuiDialog-paper-3220 {
    padding: 2rem 0;
  }
  .footerBg {
    height: 15vh;
  }
  .nextQuestionBtn {
    font: normal normal bold 16px/20px PoppinsSemiBold !important;
    margin: 0px 1.5rem !important;
    height: 90px !important;
  }
  .profileText {
    margin-left: 20px !important;
    margin-top: -10px !important;
  }
  .circleContainer {
    left: 30% !important;
  }
  .tooltip div {
    width: 30px;
    height: 30px;
  }
}

#email-verify-dialog-title {
  background-color: #fff !important;
}

#email-verify-dialog-content {
  padding: 2rem !important;
  background-color: #fff !important;
}

.MuiCircularProgress-indeterminate {
  color: #f6ac29 !important;
}

.error {
  color: #f00;
  margin-bottom: 0.5rem;
  font-family: PoppinsSemiBold;
  font-size: 18px;
}

.science_light_bg {
  color: var(--skyblue);
}

.jungle_light_bg {
  /* color: var(--green); */
  color: #4d4d4d;
}

.class_light_bg {
  color: var(--blue);
}

.selectedTheme {
  border: 2px solid #f6ac29;
  border-radius: 8px;
  opacity: 1 !important;
}

.selectedThemeTitle {
  opacity: 1 !important;
}

.selectedTheme div {
  opacity: 1 !important;
}

.nextQuestionBtn {
  font: normal normal bold 18px/20px PoppinsSemiBold;
  color: #fff !important;
  height: 70px;
  margin: 0px 2.5rem !important;
  background-image: url("./assets/images/next_btn.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 26px !important;
}

.nextQuestionBtn:hover,
.nextQuestionBtn:focus,
.nextQuestionBtn:active {
  background-color: transparent !important;
}

.primaryQuestionBtn {
  font: normal normal bold 16px/19px PoppinsBold !important;
  letter-spacing: 0;
  color: #1d1d1b !important;
  background: #ffc934 0% 0% no-repeat padding-box !important;
  width: 100%;
  height: 60px;
  border-radius: 0 !important;
  padding: 0 2.5rem !important;
  text-transform: capitalize !important;
}
.prevScreenbutton {
  font: normal normal bold 16px / 19px PoppinsBold !important;
  letter-spacing: 0;
  color: #1d1d1b !important;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 0 !important;
  padding: 0 2.5rem !important;
  text-transform: capitalize !important;
}

.suggestion-desc {
  font: normal normal 500 21px/30px PoppinsMedium;
}

.headerShadow {
  background-color: rgb(255 255 255 / 35%);
}

.icon-thumbsup,
.icon-notApplicable,
.icon-not-applicable,
.icon-improvement {
  width: 40px;
  margin: 16px 0 8px 0px;
}

@media only screen and (max-width: 590px) and (min-width: 486px) {
  .iphoneView {
    flex-direction: row-reverse;
  }
  .hide-iphone {
    display: none;
  }
}

.copyright-statement {
  font: normal normal 300 10px/14px PoppinsRegular;
  display: block;
  margin-top: 14px;
  opacity: 0.8;
  margin-top: 2em;
}

@media only screen and (min-width: 1600px) {
  .copyright-statement {
    font: normal normal 300 12px/16px PoppinsRegular;
    margin-top: 20px;
  }
}

.vl {
  border-left: 1px solid #626262;
  height: 300px;
}

.pp-title {
  font: normal normal 300 34px/48px PoppinsSemiBold;
  color: #ffcd05;
  margin: 0 auto 24px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  /* z-index: 999; */
}

.questor-logo-para {
  font: normal normal 300 24px/30px PoppinsSemiBold;
  color: #000;
}

.questor-logo-left-side {
  text-align: left;
  margin: 60px 0;
}
.questor-logo-left-side img {
  max-width: 100%;
  margin-bottom: 24px;
}

.questor-logo-small {
  width: 200px !important;
  margin-top: 18px;
  margin-bottom: 0 !important;
}

.section-desc {
  color: #000;
  font: normal normal 300 24px/30px PoppinsSemiBold;
  text-align: left;
}

.copyRight {
  font: normal normal 500 12px/18px PoppinsMedium;
}

.treeLeftSidePointsPara {
  padding-left: 40px;
}

.treeRightSidePointsPara {
  padding-right: 138px;
}

.risk-thinking-content p {
  color: #ffcd05;
  font: normal normal 300 21px/31px PoppinsSemiBold;
  margin-bottom: 36px;
}

.innovation-thinking-content p {
  color: #214697;
  font: normal normal 300 21px/31px PoppinsSemiBold;
}

.human-thinking-content p {
  color: #be2431;
  font: normal normal 300 21px/31px PoppinsSemiBold;
}
.potential-content p {
  color: #000;
  text-align: left;
  font: normal normal 300 16px/21px PoppinsMedium;
}

.test-result-container {
  background-image: url("./assets/images/report/axes-skeleton.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 950px;
  position: relative;
}

.scroingIcon {
  width: 94px;
}

.ht-1 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.ht-2 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.ht-3 {
  position: absolute;
  bottom: 162px;
  left: 167px;
  width: 100px;
}

.ht-4 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.ht-5 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.it-1 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.it-2 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.it-3 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.it-4 {
  position: absolute;
  bottom: 184px;
  right: 212px;
  width: 100px;
}

.it-5 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.rt-1 {
  position: absolute;
  top: 18%;
  left: 42%;
  width: 100px;
}

.rt-2 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.rt-3 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.rt-4 {
  position: absolute;
  bottom: 49%;
  left: 28%;
  width: 94px;
}

.rt-5 {
  position: absolute;
  bottom: 49%;
  left: 42%;
  width: 94px;
}

.animated-drawer-container {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(50px);
}

.result-outcome img {
  width: 75px;
}

.contentSection {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 650px;
}

.contentSection img {
  position: absolute;
  top: 20%;
  left: 15%;
}
.contentSection h2 {
  position: absolute;
  top: 22%;
  left: 34%;
  font: normal normal 300 24px/30px PoppinsSemiBold;
}

.contentSection p {
  position: absolute;
  text-align: left;
  font: normal normal 300 18px/24px PoppinsSemiBold;
}

.individualScore {
  position: absolute;
  width: 73px;
}

.individual-ht-1 {
  bottom: 173px;
  left: 54px;
}

.individual-ht-2 {
  bottom: 190px;
  left: 87px;
}

.individual-ht-3 {
  bottom: 206px;
  left: 120px;
}

.individual-ht-4 {
  bottom: 223px;
  left: 153px;
}

.individual-it-1 {
  bottom: 173px;
  right: 54px;
}

.individual-it-2 {
  bottom: 190px;
  right: 87px;
}

.individual-it-3 {
  bottom: 206px;
  right: 120px;
}

.individual-it-4 {
  bottom: 223px;
  right: 153px;
}

.individual-rt-1 {
  bottom: 173px;
  left: 54px;
}

.individual-rt-2 {
  bottom: 190px;
  left: 87px;
}

.individual-rt-3 {
  bottom: 206px;
  left: 120px;
}

.individual-rt-4 {
  bottom: 222px;
  left: 152px;
}

.resultLineContainer {
  position: absolute;
  bottom: 0;
  left: 50%;
  text-align: left;
}
.resultLineContainer .vl {
  height: 50px;
}

.ht-rt-it {
  position: absolute;
  top: 53%;
  left: 43.5%;
  width: 123px;
}

.equilibrim-text {
  color: #000;
  font: normal normal 300 24px/30px PoppinsSemiBold;
  position: absolute;
  bottom: 4%;
  left: 42%;
  text-align: left;
}

.ht-score-img {
  margin-top: 70px;
  width: 500px;
}

.report-custom-container {
  padding: 0 16px;
}

@media only screen and (max-width: 1050px) {
  .ht-1 {
    bottom: 153px;
    left: 61px;
    width: 84px;
  }
  .ht-2 {
    bottom: 172px;
    left: 97px;
    width: 84px;
  }
  .ht-3 {
    bottom: 190px;
    left: 135px;
    width: 84px;
  }
  .ht-4 {
    bottom: 208px;
    left: 172px;
    width: 84px;
  }

  .it-1 {
    bottom: 153px;
    left: 360px;
    width: 84px;
  }
  .it-2 {
    bottom: 172px;
    left: 320px;
    width: 84px;
  }
  .it-3 {
    bottom: 190px;
    left: 284px;
    width: 84px;
  }
  .it-4 {
    bottom: 210px;
    left: 248px;
    width: 84px;
  }

  .rt-1 {
    top: 153px;
    left: 210px;
    width: 84px;
  }
  .rt-2 {
    top: 200px;
    left: 210px;
    width: 84px;
  }
  .rt-3 {
    top: 245px;
    left: 210px;
    width: 84px;
  }
  .rt-4 {
    top: 292px;
    left: 210px;
    width: 84px;
  }
  .contentSection {
    height: 530px;
  }
  .contentSection img {
    width: 80px;
  }
  .contentSection h2 {
    font: normal normal 300 20px/30px PoppinsSemiBold;
  }

  .individualScore {
    position: absolute;
    width: 52px;
  }

  .individual-ht-1 {
    bottom: 214px;
    left: 39px;
  }

  .individual-ht-2 {
    bottom: 226px;
    left: 63px;
  }

  .individual-ht-3 {
    bottom: 237px;
    left: 87px;
  }

  .individual-ht-4 {
    bottom: 252px;
    left: 110px;
  }

  .individual-it-1 {
    bottom: 214px;
    right: 40px;
  }

  .individual-it-2 {
    bottom: 227px;
    right: 62px;
  }

  .individual-it-3 {
    bottom: 238px;
    right: 87px;
  }

  .individual-it-4 {
    bottom: 252px;
    right: 111px;
  }

  .individual-rt-1 {
    top: 215px;
    left: 135px;
  }

  .individual-rt-2 {
    top: 244px;
    left: 135px;
  }

  .individual-rt-3 {
    top: 275px;
    left: 135px;
  }

  .individual-rt-4 {
    top: 305px;
    left: 134px;
  }

  .copyRight {
    text-align: left;
    margin: 0px 25px 0px 0px !important;
  }

  .questor-logo-left-side {
    padding: 0 60px;
  }

  .ht-rt-it {
    position: absolute;
    top: 52%;
    left: 41.7%;
    width: 82px;
  }

  .equilibrim-text {
    bottom: 0;
    left: 37%;
  }

  .treeRightSidePointsPara {
    padding-right: 100px;
  }
  .ht-score-img {
    width: 400px;
  }
}

@media only screen and (min-width: 1052px) and (max-width: 1282px) {
  .ht-1 {
    bottom: 111px;
    left: 75px;
    width: 104px;
  }
  .ht-2 {
    bottom: 134px;
    left: 121px;
    width: 104px;
  }
  .ht-3 {
    bottom: 157px;
    left: 167px;
    width: 104px;
  }
  .ht-4 {
    bottom: 180px;
    left: 214px;
    width: 104px;
  }

  .it-1 {
    bottom: 111px;
    left: 445px;
    width: 104px;
  }
  .it-2 {
    bottom: 134px;
    left: 398px;
    width: 104px;
  }
  .it-3 {
    bottom: 157px;
    left: 352px;
    width: 104px;
  }
  .it-4 {
    bottom: 181px;
    left: 306px;
    width: 104px;
  }

  .rt-1 {
    top: 111px;
    left: 259px;
    width: 105px;
  }
  .rt-2 {
    top: 169px;
    left: 259px;
    width: 105px;
  }
  .rt-3 {
    top: 227px;
    left: 259px;
    width: 105px;
  }
  .rt-4 {
    top: 285px;
    left: 260px;
    width: 105px;
  }
  .contentSection {
    height: 650px;
  }

  .individualScore {
    position: absolute;
    width: 67px;
  }

  .individual-ht-1 {
    bottom: 187px;
    left: 49px;
  }

  .individual-ht-2 {
    bottom: 202px;
    left: 79px;
  }

  .individual-ht-3 {
    bottom: 217px;
    left: 109px;
  }

  .individual-ht-4 {
    bottom: 232px;
    left: 139px;
  }

  .individual-it-1 {
    bottom: 186px;
    right: 49px;
  }

  .individual-it-2 {
    bottom: 201px;
    right: 79px;
  }

  .individual-it-3 {
    bottom: 217px;
    right: 109px;
  }

  .individual-it-4 {
    bottom: 232px;
    right: 139px;
  }

  .individual-rt-1 {
    top: 186px;
    left: 169px;
  }

  .individual-rt-2 {
    top: 224px;
    left: 169px;
  }

  .individual-rt-3 {
    top: 262px;
    left: 169px;
  }

  .individual-rt-4 {
    top: 299px;
    left: 169px;
  }

  .ht-rt-it {
    position: absolute;
    top: 52.8%;
    left: 41.7%;
    width: 104px;
  }

  .equilibrim-text {
    bottom: 0 !important;
    left: 39% !important;
  }

  .suggestion-desc {
    font: normal normal 500 18px/27px PoppinsMedium;
  }
  .ht-score-img {
    margin-top: 50px;
  }

  .report-custom-container {
    padding: 0 108px;
  }

  .ht-icon {
    width: 60px;
    cursor: pointer;
  }

  .icon-thumbsup,
  .icon-notApplicable,
  .icon-not-applicable,
  .icon-improvement {
    width: 30px;
    margin: 16px 0 8px 0px;
  }

  .report-custom-container {
    padding: 0 104px;
  }

  .feedback-btn {
    padding: 6px 48px;
  }
  #riskTaking {
    font: normal normal 600 20px/31px PoppinsSemiBold;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1366px) {
  .icon-thumbsup,
  .icon-notApplicable,
  .icon-not-applicable,
  .icon-improvement {
    width: 40px !important;
    margin: 8px 0;
  }

  .ht-icon {
    width: 80px;
    cursor: pointer;
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1442px) {
  .ht-1 {
    bottom: 103px;
    left: 96px;
    width: 108px;
  }
  .ht-2 {
    bottom: 127px;
    left: 144px;
    width: 108px;
  }
  .ht-3 {
    bottom: 151px;
    left: 192px;
    width: 108px;
  }
  .ht-4 {
    bottom: 175px;
    left: 240px;
    width: 108px;
  }

  .it-1 {
    bottom: 103px;
    left: 481px;
    width: 108px;
  }
  .it-2 {
    bottom: 127px;
    left: 432px;
    width: 108px;
  }
  .it-3 {
    bottom: 151px;
    left: 384px;
    width: 108px;
  }
  .it-4 {
    bottom: 175px;
    left: 337px;
    width: 108px;
  }

  .rt-1 {
    top: 103px;
    left: 288px;
    width: 108px;
  }
  .rt-2 {
    top: 162px;
    left: 288px;
    width: 108px;
  }
  .rt-3 {
    top: 222px;
    left: 288px;
    width: 108px;
  }
  .rt-4 {
    top: 282px;
    left: 288px;
    width: 108px;
  }

  .contentSection {
    height: 800px;
  }
  .contentSection img {
    top: 22%;
  }
  .contentSection h2 {
    top: 22%;
    font: normal normal 300 28px/48px PoppinsSemiBold;
  }

  .individualScore {
    position: absolute;
    width: 75px;
  }

  .individual-ht-1 {
    bottom: 173px;
    left: 54px;
  }

  .individual-ht-2 {
    bottom: 190px;
    left: 87px;
  }

  .individual-ht-3 {
    bottom: 206px;
    left: 120px;
  }

  .individual-ht-4 {
    bottom: 219.5px;
    left: 158px;
  }

  .individual-it-1 {
    bottom: 173px;
    right: 54px;
  }

  .individual-it-2 {
    bottom: 190px;
    right: 87px;
  }

  .individual-it-3 {
    bottom: 206px;
    right: 120px;
  }

  .individual-it-4 {
    bottom: 220px;
    right: 158px;
  }

  .individual-rt-1 {
    top: 173px;
    left: 186px;
  }

  .individual-rt-2 {
    top: 214px;
    left: 186px;
  }

  .individual-rt-3 {
    top: 255px;
    left: 186px;
  }

  .individual-rt-4 {
    top: 295.5px;
    left: 191.7px;
  }

  .resultLineContainer {
    position: absolute;
    bottom: 0;
    left: 50%;
    text-align: left;
  }
  .resultLineContainer .vl {
    height: 100px;
  }

  .equilibrim-text {
    position: absolute;
    bottom: 0;
    left: 40%;
    text-align: left;
  }

  .ht-rt-it {
    position: absolute;
    top: 53%;
    left: 42.5%;
    width: 106px;
  }
  .potential-content p {
    font: normal normal 500 18px/24px PoppinsMedium;
  }
  .ht-score-img {
    margin-top: 70px;
  }

  .ht-icon {
    cursor: pointer;
    width: 100px;
  }

  .icon-thumbsup,
  .icon-notApplicable,
  .icon-not-applicable,
  .icon-improvement {
    width: 40px;
    margin: 16px 0;
  }

  .report-custom-container {
    padding: 0 104px;
  }

  .primaryQuestionBtn {
    font: normal normal 500 18px/24px PoppinsBold !important;
  }

  .prevScreenbutton {
    font: normal normal 500 18px/24px PoppinsBold !important;
  }
}

@media only screen and (min-width: 1443px) and (max-width: 1600px) {
  .ht-1 {
    bottom: 122px;
    left: 90px;
    width: 122px;
  }
  .ht-2 {
    bottom: 149px;
    left: 144px;
    width: 123px;
  }
  .ht-3 {
    bottom: 176px;
    left: 199px;
    width: 123px;
  }
  .ht-4 {
    bottom: 204px;
    left: 254px;
    width: 123px;
  }
  .it-1 {
    bottom: 122px;
    left: 528px;
    width: 122px;
  }
  .it-2 {
    bottom: 149px;
    left: 472px;
    width: 124px;
  }
  .it-3 {
    bottom: 177px;
    left: 418px;
    width: 122px;
  }
  .it-4 {
    bottom: 204px;
    left: 364px;
    width: 122px;
  }
  .rt-1 {
    top: 122px;
    left: 309px;
    width: 122px;
  }
  .rt-2 {
    top: 191px;
    left: 309px;
    width: 122px;
  }
  .rt-3 {
    top: 258px;
    left: 309px;
    width: 123px;
  }
  .rt-4 {
    top: 328px;
    left: 309px;
    width: 122px;
  }

  .individualScore {
    position: absolute;
    width: 80px;
  }

  .individual-ht-1 {
    bottom: 210px;
    left: 58px;
  }

  .individual-ht-2 {
    bottom: 228px;
    left: 94px;
  }

  .individual-ht-3 {
    bottom: 246px;
    left: 130px;
  }

  .individual-ht-4 {
    bottom: 264px;
    left: 166px;
  }

  .individual-it-1 {
    bottom: 210px;
    right: 59px;
  }

  .individual-it-2 {
    bottom: 228px;
    right: 94px;
  }

  .individual-it-3 {
    bottom: 245px;
    right: 130px;
  }

  .individual-it-4 {
    bottom: 263px;
    right: 166px;
  }

  .individual-rt-1 {
    top: 210px;
    left: 202px;
  }

  .individual-rt-2 {
    top: 254px;
    left: 202px;
  }

  .individual-rt-3 {
    top: 299px;
    left: 202px;
  }

  .individual-rt-4 {
    top: 343px;
    left: 202px;
  }
  .contentSection {
    height: 720px;
  }

  .ht-icon {
    cursor: pointer;
    width: 100px;
  }

  .icon-thumbsup,
  .icon-notApplicable,
  .icon-not-applicable,
  .icon-improvement {
    width: 40px;
    margin: 16px 0 8px 0px;
  }

  .report-custom-container {
    padding: 0 104px;
  }

  .primaryQuestionBtn {
    font: normal normal 500 18px/24px PoppinsBold !important;
  }

  .prevScreenbutton {
    font: normal normal 500 18px/24px PoppinsBold !important;
  }
  .resultLineContainer .vl {
    height: 100px;
  }
}

@media only screen and (min-width: 1601px) and (max-width: 1980px) {
  .ht-1 {
    bottom: 119px;
    left: 188px;
    width: 124px;
  }
  .ht-2 {
    bottom: 147px;
    left: 243px;
    width: 124px;
  }
  .ht-3 {
    bottom: 174px;
    left: 299px;
    width: 124px;
  }
  .ht-4 {
    bottom: 202px;
    left: 354px;
    width: 124px;
  }
  .it-1 {
    bottom: 119px;
    left: 632px;
    width: 124px;
  }
  .it-2 {
    bottom: 147px;
    left: 577px;
    width: 124px;
  }
  .it-3 {
    bottom: 175px;
    left: 522px;
    width: 123px;
  }
  .it-4 {
    bottom: 202px;
    left: 465px;
    width: 124px;
  }
  .rt-1 {
    top: 120px;
    left: 411px;
    width: 124px;
  }
  .rt-2 {
    top: 188px;
    left: 410px;
    width: 124px;
  }
  .rt-3 {
    top: 257px;
    left: 410px;
    width: 124px;
  }
  .rt-4 {
    top: 326px;
    left: 410px;
    width: 124px;
  }

  .contentSection {
    height: 900px;
  }

  .contentSection h2 {
    font: normal normal 300 32px/48px PoppinsSemiBold;
  }

  .contentSection img {
    left: 17%;
    width: 150px !important;
  }

  .individualScore {
    position: absolute;
    width: 102px;
  }

  .individual-ht-1 {
    bottom: 164px;
    left: 75px;
  }

  .individual-ht-2 {
    bottom: 187px;
    left: 121px;
  }

  .individual-ht-3 {
    bottom: 210px;
    left: 167px;
  }

  .individual-ht-4 {
    bottom: 233px;
    left: 212px;
  }

  .individual-it-1 {
    bottom: 163px;
    right: 75px;
  }

  .individual-it-2 {
    bottom: 187px;
    right: 121px;
  }

  .individual-it-3 {
    bottom: 210px;
    right: 166px;
  }

  .individual-it-4 {
    bottom: 234px;
    right: 213px;
  }

  .individual-rt-1 {
    top: 164px;
    left: 258px;
  }

  .individual-rt-2 {
    top: 220px;
    left: 258px;
  }

  .individual-rt-3 {
    top: 278px;
    left: 258px;
  }

  .individual-rt-4 {
    top: 335px;
    left: 258px;
  }
  .ht-score-img {
    width: 700px;
  }

  .ht-icon {
    cursor: pointer;
    width: 120px;
  }

  .icon-thumbsup,
  .icon-notApplicable,
  .icon-not-applicable,
  .icon-improvement {
    width: 50px;
    margin: 20px 0;
  }

  .report-custom-container {
    padding: 0 140px;
  }

  .primaryQuestionBtn {
    font: normal normal 500 21px/30px PoppinsBold !important;
  }

  .prevScreenbutton {
    font: normal normal 500 21px/30px PoppinsBold !important;
  }
  .resultLineContainer .vl {
    height: 100px;
  }
}

/* @media screen and (min-width: 320px) and (max-width: 800px) and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

@media only screen and (min-width: 1513px) and (max-width: 1680px) {
  .ht-score-img {
    width: 600px;
  }
}

.ht-suggestion-title-color {
  color: #be2431;
}

.it-suggestion-title-color {
  color: #214697;
}

.rt-suggestion-title-color {
  color: #ffcd05;
}

.d-flex {
  display: flex;
}

.thumb-icon {
  width: 75px;
}

.resultImage {
  max-width: 100%;
  padding: 20px;
}

.groupTitle {
  color: #be2431;
  font: normal normal 300 48px/120px PoppinsSemiBold;
  margin: 0 auto;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
}

.title-rt {
  color: #ffcd05;
}

.title-it {
  color: #214697;
}

.title-ht {
  color: #be2431;
}

.resultImg {
  max-width: 100%;
}

.resultImgContainer {
  height: auto;
  width: 100%;
  position: relative;
}

.yellow-color {
  color: #ffcd05;
}

.blue-color {
  color: #214697;
}

.red-color {
  color: #be2431;
}

.opacity-50 {
  opacity: 50%;
}

.feedback-submitted {
  background-color: #fff;
  color: #000;
}
.feedback-btn:disabled {
  cursor: auto;
}

#feedback-bad,
#feedback-good,
#feedback-okay {
  cursor: pointer;
  max-width: 100%;
  width: 70%;
}

.disbaleImage {
  pointer-events: none;
}

.img-map-container {
  position: relative;
}

.tooltip {
  position: absolute;
  color: #fff;
  /* padding: 10px; */
  /* background: rgba(0, 0, 0, 0.8); */
  transform: translate3d(-50%, -50%, 0);
  border-radius: 5px;
  pointer-events: none;
  z-index: 1000;
}

.pp-section-container {
  margin: 0 auto;
}

.entreEquilibriumContainer {
  align-items: center;
}

@media only screen and (max-width: 1850px) {
  .hide-lg {
    display: block;
  }
  .show-lg {
    display: none;
  }
}

@media only screen and (min-width: 1855px) {
  .hide-lg {
    display: none;
  }
  .show-lg {
    display: block;
  }
}

.customTextField:focus-visible {
  outline: none;
}

::placeholder {
  color: #fefefe;
  letter-spacing: 0px;
}

:-ms-input-placeholder {
  color: #fefefe;
  letter-spacing: 0px;
}

::-ms-input-placeholder {
  color: #fefefe;
  letter-spacing: 0px;
}

.defaultHeader {
  box-shadow: 0px 5px 12px #00000017;
}

.MuiBackdrop-root-1115 {
  background-color: rgba(255, 255, 255, 1) !important;
}

.MuiBackdrop-root-2795 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.custom-modal-container {
  text-align: center;
  background-color: rgb(0 0 0 / 60%) !important;
  backdrop-filter: blur(25px);
  color: #fff;
}

.custom-modal-container div > div {
  box-shadow: none !important;
  background: transparent !important;
  color: #fff !important;
  position: static;
}

.customized-dialog-title {
  font: normal normal bold 24px/30px PoppinsBold;
  letter-spacing: 0;
  color: "#fff";
  padding: "0 3rem";
  margin-top: "10px";
}
.modal-close-button {
  position: absolute;
  top: 10%;
  right: 10%;
}

.flex-center {
  display: flex;
  align-items: center;
}

.custom-infoGraphic-image {
  height: calc(100vh - 60px);
  /* background-size: cover; */
  /* background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url("./assets/images/home-banner.png"); */
  width: 100%;
}

.pnf-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 60px);
  flex-direction: column;
}

.pnf-container h1 {
  font: normal normal bold 120px/30px PoppinsBold;
}

.pnf-container h3 {
  font: normal normal bold 24px/18px PoppinsSemiBold;
}

.enableOTPBtn {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: all;
}

.disableOTPBtn {
  opacity: 0.4 !important;
  pointer-events: none;
}

.primaryButton {
  background: #ffcd05 0% 0% no-repeat !important;
  border-radius: 23px !important;
  opacity: 0.4;
  color: #1d1d1b !important;
  font-size: 16px !important;
  line-height: 19px !important;
  font-family: PoppinsMedium !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  padding: 12px 23px !important;
  /* "&:hover, &:focus": {
      background: "#ffcd05 0% 0% no-repeat padding-box"
  }; */
}

.OTPMobileNumber {
  letter-spacing: 12px;
}

.primary-link {
  color: #ffcd05 !important;
  text-decoration: underline !important;
  font-size: 14px !important;
  line-height: 28px !important;
  font-family: PoppinsSemiBold !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.htTextColor {
  color: var(--ht) !important;
}

.itTextColor {
  color: var(--it) !important;
}

.rtTextColor {
  color: var(--rt) !important;
}

.greenColor {
  color: var(--green) !important;
}

.pr-36 {
  padding-right: 36px;
}

.pos-rel {
  position: relative;
}

.MuiButtonBase-root.Mui-disabled {
  opacity: 0.5;
}

::-webkit-progress-bar {
  background-color: #fff !important;
}
::-webkit-progress-value {
  background-color: #6fbc44 !important;
}

#progressBar::-webkit-progress-bar {
  background-color: #fff !important;
}
#progressBar::-webkit-progress-value {
  background-color: #6fbc44 !important;
}

.container--ph {
  height: 0;
  padding-top: 48%;
  position: relative;
}

.svg--responsive {
  position: absolute;
  top: 0;
  left: 0;
}

.home-banner {
  max-width: 100%;
  height: calc(100vh - 60px);
  width: 100%;
}

/* .fade-in-text {
  animation: fadeIn 5s;
  animation-iteration-count: infinite;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
} */

/* @keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.elementToFadeInAndOut {
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-name: fadeinout;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: fadeinout;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.customRadioButtonLabel::first-letter {
  text-transform: uppercase;
}

.listingBulletContainer {
  max-width: 3% !important;
  width: 3% !important;
}
.listingBulletTextContainer {
  max-width: 97% !important;
  width: 97% !important;
}

.listingBulletContainer p,
.listingBulletTextContainer p {
  margin: 0;
  line-height: 24px;
}

.hideInfoButton {
  display: none !important;
}
