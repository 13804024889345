.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "RalewayBold";
  src: local("MyFont"), url(./fonts/Raleway-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "RalewayExtraBold";
  src: local("MyFont"), url(./fonts/Raleway-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "RalewaySemiBold";
  src: local("MyFont"), url(./fonts/Raleway-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "RalewayMedium";
  src: local("MyFont"), url(./fonts/Raleway-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "RalewayLight";
  src: local("MyFont"), url(./fonts/Raleway-Light.ttf) format("truetype");
}

@font-face {
  font-family: "RalewayRegular";
  src: local("MyFont"), url(./fonts/Raleway-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "RalewayThin";
  src: local("MyFont"), url(./fonts/Raleway-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoMedium";
  src: local("MyFont"), url(./fonts/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoBold";
  src: local("MyFont"), url(./fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SalsaRegular";
  src: local("MyFont"), url(./fonts/Salsa-Regular.ttf) format("truetype");
}

/* Report Font Family */

@font-face {
  font-family: "PoppinsBold";
  src: local("MyFont"), url(./fonts/Report/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsExtraBold";
  src: local("MyFont"),
    url(./fonts/Report/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("MyFont"),
    url(./fonts/Report/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("MyFont"),
    url(./fonts/Report/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsLight";
  src: local("MyFont"), url(./fonts/Report/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("MyFont"),
    url(./fonts/Report/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsThin";
  src: local("MyFont"), url(./fonts/Report/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "LibreFranklinExtraBold";
  src: local("MyFont"),
    url(./fonts/LibreFranklin/LibreFranklin-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "LibreFranklinBold";
  src: local("MyFont"),
    url(./fonts/LibreFranklin/LibreFranklin-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "LibreFranklinSemiBold";
  src: local("MyFont"),
    url(./fonts/LibreFranklin/LibreFranklin-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "LibreFranklinMedium";
  src: local("MyFont"),
    url(./fonts/LibreFranklin/LibreFranklin-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "LibreFranklinLight";
  src: local("MyFont"),
    url(./fonts/LibreFranklin/LibreFranklin-Light.ttf) format("truetype");
}

@font-face {
  font-family: "LibreFranklinRegular";
  src: local("MyFont"),
    url(./fonts/LibreFranklin/LibreFranklin-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "LibreFranklinThin";
  src: local("MyFont"),
    url(./fonts/LibreFranklin/LibreFranklin-Thin.ttf) format("truetype");
}

/* @font-face {
  font-family: 'RobotoMedium';
  src: local('MyFont'), url(./fonts/Roboto-Medium.ttf) format('truetype');
} */

@font-face {
  font-family: "RobotoBold";
  src: local("MyFont"), url(./fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SalsaRegular";
  src: local("MyFont"), url(./fonts/Salsa-Regular.ttf) format("truetype");
}

@keyframes l-rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes r-rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}

/* -- CIRCLE -- */

.circle {
  width: 100px;
  height: 100px;
  /* position: relative; */
  border-radius: 999px;
  box-shadow: inset 0 0 0 20px #e9ebf0;
}

.l-half:before,
.r-half:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 20px solid #ffcd05;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
}

.l-half:before {
  border-right: none;
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
  -webkit-transform-origin: center right;
  -webkit-animation-name: l-rotate;
}

.l-half,
.r-half {
  float: left;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.r-half:before {
  border-left: none;
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
  -webkit-transform-origin: center left;
  -webkit-animation-name: r-rotate;
}

/* -- TIMER -- */

.count {
  position: absolute;
  width: 100%;
  line-height: 100px;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  font-family: RobotoBold;
  letter-spacing: 0px;
  color: #214697;
  z-index: 2;
  -webkit-animation: fadeout 0.5s 10s 1 linear;
  -webkit-animation-fill-mode: forwards;
}

nav {
  /* margin: 100px auto;
  text-align: center; */
}

nav ul ul {
  display: none;
}

nav ul ul:before {
  position: absolute;
  top: -9px;
  right: 16px;
  display: inline-block;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #111;
  border-left: 9px solid transparent;
  content: "";
}

nav ul li:hover > ul {
  display: block;
  text-align: left;
  border-radius: 10px;
}

nav ul {
  background: none;
  /* background: linear-gradient(top, #efefef 0%, #bbbbbb 100%);
  background: -moz-linear-gradient(top, #efefef 0%, #bbbbbb 100%);
  background: -webkit-linear-gradient(top, #efefef 0%, #bbbbbb 100%); */
  box-shadow: none;
  /* box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15); */
  padding: 0 0px;
  border-radius: 10px;
  list-style: none;
  position: relative;
  display: inline-table;
}

nav ul:after {
  content: "";
  clear: both;
  display: block;
}

nav ul li {
  float: left;
}

nav ul li:hover {
  background: none;
  /* background: linear-gradient(top, #4f5964 0%, #5f6975 40%);
  background: -moz-linear-gradient(top, #4f5964 0%, #5f6975 40%);
  background: -webkit-linear-gradient(top, #4f5964 0%, #5f6975 40%); */
}

nav ul li:hover a {
  color: #fff;
  /* color: #111; */
  font: normal normal 600 12px/24px LibreFranklinSemiBold;
  letter-spacing: 0px;
  padding: 0;
}

nav ul li:hover ul li a {
  padding: 6px 15px;
  cursor: pointer;
}

nav ul li a {
  display: block;
  padding: 0;
  color: #757575;
  text-decoration: none;
  font: normal normal 600 12px/24px LibreFranklinSemiBold;
}

nav ul ul {
  background: #1d1d1b;
  border-radius: 0px;
  padding: 0;
  position: absolute;
  top: 120%;
  right: -10%;
  z-index: 1;
  width: 116px;
}

nav ul ul li {
  float: none;
  /* border-top: 1px solid #6b727c;
  border-bottom: 1px solid #575f6a; */
  position: relative;
}

nav ul ul li a {
  padding: 15px 40px;
  color: #fff;
}

nav ul ul li a:hover {
  /* background: #4b545f; */
}

nav ul ul ul {
  position: absolute;
  left: 100%;
  top: 0;
}

.circleContainer {
  position: absolute;
  left: -7%;
  top: 40%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 4rem;
  z-index: 999;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  cursor: pointer;
}

.cls-1,
.cls-2 {
  fill: #ffcc02;
  stroke: #231f20;
  stroke-width: 1px;
  stroke-opacity: 0.5 !important;
}

.cls-3,
.cls-4 {
  fill: #be2331;
  stroke: #231f20;
  stroke-width: 1px;
  stroke-opacity: 0.5 !important;
}

.cls-5,
.cls-6 {
  fill: #204697;
  stroke: #231f20;
  stroke-width: 1px;
  stroke-opacity: 0.5 !important;
}

.cls-7 {
  fill: #231f20;
  stroke: #231f20;
  stroke-width: 0.25px;
  stroke-opacity: 0.5 !important;
}

.cls-2,
.cls-4,
.cls-6 {
  stroke: #231f20;
  stroke-miterlimit: 10;
  stroke-width: 1px;
  stroke-opacity: 1 !important;
}

.cls-8 {
  opacity: 0.2 !important;
}

.cls-9 {
  opacity: 0.4 !important;
}

.cls-10 {
  opacity: 0.6 !important;
}

.cls-11 {
  opacity: 0.8 !important;
}
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.2 !important;
}

.opacity-2 {
  opacity: 0.4 !important;
}

.opacity-3 {
  opacity: 0.6 !important;
}

.opacity-4 {
  opacity: 0.8 !important;
}

.opacity-5,
.opacity-1 {
  opacity: 1 !important;
}

.st0 {
  fill: #be2331;
  stroke: #231f20;
  stroke-width: 1px;
}
.st1 {
  opacity: 0.8;
}
.st2 {
  opacity: 0.6;
}
.st3 {
  opacity: 0.4;
}
.st4 {
  opacity: 0.2;
}
.st5 {
  fill: #204697;
  stroke: #231f20;
  stroke-width: 1px;
}
.st6 {
  fill: #ffcc02;
  stroke: #231f20;
  stroke-width: 1px;
}
.st7 {
  fill: #be2331;
  stroke: #231f20;
  stroke-width: 0.1562;
  stroke-miterlimit: 10;
}
.st8 {
  fill: #204697;
  stroke: #231f20;
  stroke-width: 0.1562;
  stroke-miterlimit: 10;
}
.st9 {
  fill: #ffcc02;
  stroke: #231f20;
  stroke-width: 0.1562;
  stroke-miterlimit: 10;
}
.st10 {
  fill: #231f20;
  stroke: #231f20;
  stroke-width: 1px;
  stroke-opacity: 0.5;
}
.d-none {
  display: none;
}
